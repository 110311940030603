<template>
  <draggable v-model="computedData" v-bind="dragOptions">
    <RowBlock
      class="mb-4"
      v-for="(row, rowIndex) in computedData"
      v-bind:key="rowIndex + '-' + row.id"
      v-bind:blocks.sync="computedData"
      v-bind:blockIndex="rowIndex"
      v-on:remove="onRemove"
      v-model="row.block"
    >
      <div
        class="d-flex"
        v-bind:style="{
          gap: `${row.block.rowGap}px ${row.block.columnGap}px`,
        }"
      >
        <RowColumn
          v-for="(column, index) in row.columns"
          v-bind:colIndex="index"
          v-bind:columns="row.columns"
          v-bind:key="`column-${index}`"
        />
      </div>
    </RowBlock>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
const RowColumn = () => import("./components/RowColumn/Index.vue");
const RowBlock = () => import("./components/RowBlock/Index.vue");
export default {
  name: "WePageContent",
  components: {
    draggable,
    RowBlock,
    RowColumn,
  },
  props: {
    blocks: {
      default: () => [],
    },
  },
  methods: {
    onRemove(index) {
      this.computedData.splice(index, true);
    },
  },
  computed: {
    computedData: {
      get() {
        return this.blocks;
      },
      set(val) {
        this.$emit("update:blocks", val);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>

<style lang="scss">
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>